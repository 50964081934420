import {Component, ViewEncapsulation} from '@angular/core';
import {WalletService} from '../../../../services/Wallet/WalletService';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../../../services/General/ToastService';
import {TenantService} from '../../../../services/Tenant/TenantService';

@Component({
  selector: 'app-wallet-top-up',
  templateUrl: './wallet-top-up-modal.component.html',
  styleUrls: ['./wallet-top-up-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WalletTopUpModalComponent {
  disableBtn = false;
  externalId: string;
  enteringAmount = false;
  customAmount: string;
  tenantUrl: string;

  constructor(private service: WalletService, private browser: InAppBrowser,
              private modalCtrl: ModalController, private toaster: ToastService,
              private tenantService: TenantService) {
    this.tenantUrl = this.tenantService.getName() === 'MeetDistrict' ? 'meetdistrict.com' : 'app.digitalhq.com';
  }

  topUp(amount = null) {
    if (!amount) {
      if (!this.customAmount) {
        this.toaster.toast('Enter a valid amount');
        return;
      }
      amount = parseInt(this.customAmount, 10);
    }
    this.disableBtn = true;
    this.service.topUp(amount).then(data => {
      this.externalId = data.external_id;
      const browser = this.browser.create(data.checkoutUrl, null, {
        zoom: 'no',
        location: 'no',
      });
      browser.on('exit').subscribe(() => {
        this.modalCtrl.dismiss();
        this.disableBtn = false;
      });
      browser.on('loadstop').subscribe((event) => {
        if (!event.url.startsWith('http') || event.url.includes(this.tenantUrl)) {
          this.modalCtrl.dismiss();
          this.disableBtn = false;
          browser.close();
          this.browser.create(event.url, '_system');
        }
      });
    });
  }

  enterAmount() {
    this.enteringAmount = true;
  }

}
