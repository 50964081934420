import {Injectable} from '@angular/core';
import {CacheService as service} from 'ionic-cache';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(private cache: service) {
  }

  get(key: string, promise: Promise<any>, ttl: number = 60 * 60) {
    return this.cache.getItem(key).catch(() =>
      promise.then(result =>
        this.cache.saveItem(key, result, null, ttl).then(() => result)
      )
    );
  }

  getWithDelayedObservable(key: string, promise: Promise<any>, ttl: number = 60 * 60) {
    return this.cache.loadFromDelayedObservable(key, from(promise), null, ttl,'all');
  }

  remove(key: string) {
    return this.cache.removeItem(key);
  }

  clear() {
    return this.cache.clearAll();
  }

}
