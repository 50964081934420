import {Injectable} from '@angular/core';
import {AppApiService} from './API/AppApiService';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {NavController, Platform} from '@ionic/angular';
import {AuthService} from '../Auth/AuthService';
import {LocationService} from '../Location/LocationService';
import {NotificationService} from '../Notifications/NotificationService';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  lastActive = new BehaviorSubject<number>((new Date()).getTime());

  constructor(private api: AppApiService, private appVersion: AppVersion,
              private platform: Platform, private auth: AuthService,
              private location: LocationService, private notifications: NotificationService,
              private nav: NavController) {
    this.init();
  }

  init() {
    this.platform.resume.subscribe(() => {
      this.auth.isLoggedIn().then(isLoggedIn => {
        if (isLoggedIn) {
          this.location.log();
          this.notifications.updateCount();
          if (this.lastActive.value + 1000 * 60 * 5 < new Date().getTime()) {
            this.nav.navigateRoot(['/meet']);
          }
        }
      });
    });
    this.platform.pause.subscribe(() => {
      this.lastActive.next((new Date()).getTime());
    });
  }

  public minAppVersion() {
    return this.api.minAppVersion();
  }

  public latestAppVersion() {
    return this.api.latestAppVersion();
  }


  public checkOutdated() {
    return this.minAppVersion().then(version => this.checkVersions(version));
  }

  public checkUpdateAvailable() {
    return this.latestAppVersion().then(version => this.checkVersions(version));
  }

  public checkVersions(checkVersion: string) {
    return this.appVersion.getVersionNumber().then(appVersion => {
      const checkVersionArr = checkVersion.split('.').map(versionNumber => parseInt(versionNumber, 10));
      const appVersionArr = appVersion.split('.').map(versionNumber => parseInt(versionNumber, 10));
      return checkVersionArr[0] > appVersionArr[0] ||
        (checkVersionArr[0] === appVersionArr[0] && checkVersionArr[1] > appVersionArr[1]) ||
        (checkVersionArr[0] === appVersionArr[0] && checkVersionArr[1] === appVersionArr[1] && checkVersionArr[2] > appVersionArr[2]);
    });
  }

  public async getTrackingStatus(): Promise<AppTrackingStatusResponse> {
    return await AppTrackingTransparency.getStatus();
  }

  public async requestTrackingPermission(): Promise<AppTrackingStatusResponse> {
    return await AppTrackingTransparency.requestPermission();
  }
}
