import {Component, OnInit} from '@angular/core';
import {App} from '@capacitor/app';
import {AuthService} from './services/Auth/AuthService';
import {NotificationService} from './services/Notifications/NotificationService';
import {NavController, Platform} from '@ionic/angular';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {OneSignalService} from './services/Notifications/OneSignalService';
import {UserService} from './services/User/UserService';
import {SplashScreen} from '@capacitor/splash-screen';
import {NetworkService} from './services/Network/NetworkService';
import {DeeplinkService} from './services/Deeplinks/DeeplinkService';
import {NfcService} from './services/NFC/NfcService';
import {AppService} from './services/App/AppService';
import {CacheService} from './services/Cache/CacheService';
import {CarParkService} from './services/CarParks/CarParkService';
import {StatusBarService} from './services/General/StatusBarService';
import {KeyboardService} from './services/General/KeyboardService';
import {LocationService} from './services/Location/LocationService';
import Bugsnag from '@bugsnag/js';
import Swiper, {Navigation, Pagination} from 'swiper';
import {ModuleService} from './services/Modules/ModuleService';
import {TenantService} from './services/Tenant/TenantService';
import {CalendarService} from './services/Calendars/CalendarService';
import {OfficeService} from './services/General/OfficeService';
import {DateTime} from 'luxon';
import {MeetingService} from './services/Meetings/MeetingService';

Swiper.use([Navigation, Pagination]);

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Office;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loggedIn = false;
  pageScrolling = true; //TODO: make this variable

  constructor(private nav: NavController, private platform: Platform,
              private auth: AuthService, private cache: CacheService,
              private notifications: NotificationService, private screenOrientation: ScreenOrientation,
              private oneSignal: OneSignalService, private user: UserService,
              private network: NetworkService, private deeplinks: DeeplinkService,
              private nfc: NfcService, private appService: AppService,
              private carParkService: CarParkService, private statusBar: StatusBarService,
              private keyboard: KeyboardService, private location: LocationService,
              private modules: ModuleService, private tenantService: TenantService,
              private calendarService: CalendarService, private officeService: OfficeService,
              private meetingService: MeetingService) {
    SplashScreen.show({
      autoHide: false
    });

    // Office.js specific initialization moved to a function
    if (window.location.search.indexOf('inOffice=true') > -1) {
      (window as any).initializeAddin = this.initializeAddin.bind(this);

      window.dispatchEvent(new Event('office_init_func_registered'));

      console.log('bound initialize');
    }
  }

  initializeAddin() {
    console.log('Add-in initialized.');
      this.officeService.setOffice(Office);
  }

  ngOnInit() {
    this.handleTenant();
    if (!this.platform.is('desktop')) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
    this.platform.backButton.subscribeWithPriority(5, () => {
      this.nav.pop().then(popped => {
        if (!popped) {
          this.nav.navigateRoot(['/home']);
        }
      });
    });

    this.auth.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        this.handleLoggedIn();
      }
    });

    this.auth.loggedIn.subscribe(status => {
      this.loggedIn = status;
      if (this.platform.is('ios')) {
        this.appService.requestTrackingPermission();
      }
    });
  }

  handleTenant() {
    this.modules.get();
    this.tenantService.tenantConfig.subscribe((data) => {
      if (data) {
        this.initializeTenantAfter();
        App.getInfo().then(info => {
          if (info.id === 'com.meetdistrict.MeetDistrict') {
            fetch('/assets/md_modules.json').then(response => {
              response.json().then(responseData => {
                this.modules.set(responseData.modules);
              });
            });
          } else {
            this.modules.set(this.tenantService.tenantConfig.value.modules);
          }
        }).catch(() => {
          // this.modules.set(this.tenantService.tenantConfig.value.modules); //Get tenant data

          // fetch('/assets/md_modules.json').then(response => {
          //   response.json().then(data => {
          //     this.modules.set(data.modules);
          //   });
          // });

          fetch('/assets/web_modules.json').then(response => {
            response.json().then((m: any) => {
              this.modules.set(m.modules);
            });
          });
        });
      }
    });
    this.initializeTenant();
  }

  initializeTenantAfter() {
    this.tenantService.setColors(this.tenantService.tenantConfig.value.colors);
    //Set the tenant specific onesignal external id
    this.user.profile().then(profile => {
      this.oneSignal.setUser(profile.id);
    });
  }

  async initializeTenant() {
    await this.tenantService.setConfigTenant();
  }

  handleLoggedIn() {
    if (this.platform.is('ios')) {
      // this.appService.getTrackingStatus().then(response => {
      //   if (response.status === 'notDetermined') {
      this.appService.requestTrackingPermission();
      // }
      // });
    }


    this.notifications.updateCount();
    this.nav.navigateRoot(['/meet']);
    this.loggedIn = true;
    this.user.profile().then(profile => {
      Bugsnag.setUser(profile.id, profile.email, profile.firstname + ' ' + profile.lastname);
    });
    this.location.log();
    this.preloadCarParks();

    if (this.platform.is('ios')) {
      this.handleAppleSpecifics();
    } else {
      this.handleAndroidSpecifics();
    }

    setTimeout(() => {
      SplashScreen.hide({fadeOutDuration: 500});
    }, 500);
    this.appService.checkOutdated().then(outdated => {
      if (outdated) {
        this.nav.navigateRoot(['/splash'], {state: {type: 'update-app'}});
      }
    });

    if (this.officeService.office !== null) {
      this.checkExistingMeetings();
    } else {
      this.officeService.onOfficeSet(() => {
        this.checkExistingMeetings();
      });
    }
  }

  checkExistingMeetings() {
    console.log('GET OFFICE TIMES');
    this.officeService.getTimes().then((times: any) => {
      if (times.from && times.till) {
        const from = DateTime.fromJSDate(times.from);
        const till = DateTime.fromJSDate(times.till);
        this.meetingService.meetings(from, till).then((meetings) => {
          if (meetings.length) {
            this.nav.navigateBack(['/meeting', {id: meetings[0].id}]);
          }
        });
      }
    });
  }

  handleAppleSpecifics() {
    this.statusBar.setLightColor();
  }

  handleAndroidSpecifics() {
    this.statusBar.forceLightColor();
  }

  preloadCarParks() {
    this.cache.get('personal-car-parks', this.carParkService.carParks());
  }

}
