import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {PreferenceService} from './PreferenceService';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private store: Storage | null = null;
  private safeKeys = [
    'more-modal-break-point',
    'parking-modal-break-point',
    'wallet-meta-data',
    'onboarding-cleared',
    'calendars',
    'calendar-colors',
    'has-seen-printing-page',
    'main-coffee-machine',
    'last-used-coffee-machines',
    'asked-app-icon-change',
    'set-app-icon',
    'tenant-config',
    'tenant-url',
  ];
  private shouldUsePreference = [
    'token',
    'refreshToken',
  ];

  constructor(private storage: Storage, private preference: PreferenceService) {
  }

  async init() {
    if (!this.store) {
      this.store = await this.storage.create();
    }
  }

  public set(key: string, value: any) {
    if (this.shouldUsePreference.includes(key)) {
      return this.preference.set(key, value);
    } else {
      return this.init().then(() => this.store?.set(key, value));
    }
  }

  public get(key: string) {
    if (this.shouldUsePreference.includes(key)) {
      return this.preference.get(key).then(token => {
        if (token) {
          return this.init().then(() => {
            this.store?.remove('token');
            this.store?.remove('refreshToken');
            return token;
          });
        } else {
          return this.init().then(() => {
            return this.store?.get('token').then((localStoreToken) => {
              if (localStoreToken) {
                this.preference.set('token', localStoreToken);
                this.store?.remove('token');
                return this.store?.get('refreshToken').then((localStoreRefreshToken) => {
                  if (localStoreRefreshToken) {
                    this.preference.set('refreshToken', localStoreRefreshToken);
                    this.store?.remove('refreshToken');
                  }
                  return key === 'token' ? localStoreToken : localStoreRefreshToken;
                });
              } else {
                return null;
              }
            });
          });
        }
      });
    } else {
      return this.init().then(() => this.store?.get(key));
    }
  }

  public remove(key: string) {
    if (this.shouldUsePreference.includes(key)) {
      return this.preference.remove(key);
    } else {
      return this.storage.remove(key);
    }
  }

  public clear() {
    this.preference.clear();
    return this.storage.keys().then(keys => {
      keys.forEach(key => {
        if(!this.safeKeys.includes(key)) {
          this.storage.remove(key);
        }
      });
    });
  }
}
